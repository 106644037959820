<template>
  <div>
    <BaseHeader
      :title="'Общая информация'"
      close-btn
      :save-btn="false"
      :delete-btn="false"
      @close="handleClose"
    />
    <v-container class="info-container" :style="$route.name === 'map' ? 'max-height: 65vh, overflow: hidden' : ''">
      <v-card class="text-xl-h5 overflow-hidden">
        <v-card-title>
          Информация о пользователе
        </v-card-title>
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="(item, key) in infoUser"
            :key="key"
          >
            <v-list-item-content class="pt-2 pb-2">
              <v-list-item-title>{{ $t(`verification.${key}`) }}</v-list-item-title>
              <v-list-item-subtitle>{{ item }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <v-card class="text-xl-h5 mt-6">
        <v-card-title>
          Данные верификации
        </v-card-title>
        <v-divider></v-divider>
        <v-list>
          <v-list-item
            v-for="(item, key) in infoVerification"
            :key="key"
          >
            <v-list-item-content class="pt-2 pb-2">
              <v-list-item-title>{{ $t(`verification.${key}`) }}</v-list-item-title>
              <v-list-item-subtitle :style="key === 'status' ? 'color:' + statusData[detail.status].color : ''">
                {{ item }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import BaseMaskedInput from '@/components/base/forms/BaseMaskedInput'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import verification from '@/components/views/account/verification/verification'
import formatters from '@/components/mixins/formatters'
import backToTables from '@/components/mixins/backToTables'

export default {
  name: 'InfoAboutCommit',
  mixins: [verification, formatters, backToTables],
  components: { BaseMaskedInput, BaseHeader },
  computed: {
    infoUser () {
      const user = {}
      user.name = this.detail.user.username || '—'
      user.organization = this.detail.user.organization.name || '—'
      user.mail = this.detail.user.email || '—'
      user.phone = this.detail.user.phone || '—'
      return user
    },
    infoVerification () {
      const verification = {}
      verification.title = this.detail.passport.properties.object_name || '—'
      verification.cadastr_number = this.detail.passport.properties.cadastr_number || '—'
      verification.created_at = this.detail?.created_at ? this.timestampToDatetime(this.detail?.created_at) : '—'
      verification.note = this.detail?.note || '—'
      verification.status = this.statusData[this.detail.status].title
      return verification
    }
  },
  data () {
    return {
      statusData: {
        1: {
          color: 'orange',
          title: 'Ожидание верификации'
        },
        2: {
          color: 'red',
          title: 'Отклонено'
        },
        3: {
          color: 'green',
          title: 'Подтверждено'
        },
        4: {
          color: 'blue',
          title: 'В процессе'
        },
        5: {
          color: 'red',
          title: 'Ошибка верификации'
        }
      }
    }
  }
}
</script>

<style scoped>
.info-container {
  max-width: unset;
}
</style>
