export default {
  props: {
    value: Object
  },
  methods: {
    handleClose () {
      this.$router.push({ name: 'verification' })
    },
  },
  computed: {
    detail: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}